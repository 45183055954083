import { IconButton, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { I18n, setLocale } from "react-redux-i18n";
import { PageBox, Nav } from "../../components";
import AutorenewIcon from '@mui/icons-material/Autorenew';
import { color } from "../../theme";
import api, { GetAreaRes, GetKioskInventoryRes } from "../../api";
import { PostKioskVendingUserStarsParameters, DeleteKioskVendingUserStarsParameters } from "../../api/api-parameters-type";
import { useAppDispatch, useAppSelector } from "../../store/hooks.ts";
import { ActionConfigRes, setConfigData } from "../../actions";
import { HomeKioskInventoryList } from "./HomeKioskInventoryList";
import { HomeSelect } from "./HomeSelect";
import { HomeQrModal } from "./HomeQrModal";
import DensitySmallTwoToneIcon from '@mui/icons-material/DensitySmallTwoTone';
import { HomeDrawer } from "./HomeDrawer";

type RightViewType = {
    onAction(): void
}

let loadingNumberInterval: NodeJS.Timer

const RightView = (props: RightViewType) => {
    const [loadingNumber, setLoadingNumber] = useState(0)


    const setLoadingNumberInterval = () => {
        let index = 10
        loadingNumberInterval = setInterval(() => {
            setLoadingNumber(index)
            if (index <= 0) {
                clearLoadingNumberInterval()
            }
            index--
        }, 1000);
    }
    const clearLoadingNumberInterval = () => {
        loadingNumberInterval && clearInterval(loadingNumberInterval)
    }

    const onAction = () => {
        clearLoadingNumberInterval()
        setLoadingNumberInterval()
        props.onAction()

    }

    useEffect(() => {
        (async () => {

        })()
        return () => {
            clearLoadingNumberInterval()
        }
    }, [])

    return (
        <>
            <IconButton
                onClick={onAction}
                size="large"
                edge="end"
                color={'primary'}
                aria-label="menu"
                disabled={loadingNumber > 0}
            >
                <AutorenewIcon />
                {loadingNumber > 0 && (
                    <Typography
                        align={'center'}
                        color={'primary'}
                        variant="body2"
                        component="div"
                        sx={{
                            position: 'absolute',
                            right: 2,
                            bottom: 2,
                            color: color.black_3,
                        }}
                    >
                        {loadingNumber}
                    </Typography>
                )}

            </IconButton>
        </>

    )
}



type LeftViewType = {
    onAction(): void
}


const LeftView = (props: LeftViewType) => {

    useEffect(() => {
        (async () => {
        })()
        return () => {
        }
    }, [])

    return (
        <>
            <IconButton
                onClick={props.onAction}
                size="large"
                edge="end"
                color={'primary'}
                aria-label="menu"
            >
                <DensitySmallTwoToneIcon />
            </IconButton>
        </>

    )
}



const getKioskInventoryList = async () => {
    const res = await api.GetKioskInventoryList()
    if (res.kind !== 'ok') throw new Error(res.kind)
    return res.data

}


const getAreaList = (kioskInventories: (GetKioskInventoryRes)[]) => {


    const areas: (GetAreaRes & { kioskCount: number })[] = [{
        id: 'ALL',
        status: 'NORMAL',
        name: {
            default: 'Show all areas',
            "zh-cn": '显示所有区域',
            "zh-tw": '顯示所有區域',
            en: 'Show all areas',
        },
        kioskCount: kioskInventories.length,
    }]

    return kioskInventories.reduce((pV, cV) => {

        const cVArea = cV.area

        if (cVArea && !pV.some(area => area.id === cVArea.id)) {

            const kioskCount = kioskInventories.filter(kioskInventory => kioskInventory.area?.id === cVArea.id).length

            pV.push({
                ...cVArea,
                kioskCount
            })
        }

        return pV
    }, areas)
}


type HomeBodyTitleType = {

    style?: React.CSSProperties
    title: string
}

const HomeBodyTitle = (props: HomeBodyTitleType) => {
    return (
        <Typography
            color={'primary'}
            variant="subtitle2"
            component="div"
            sx={{
                fontWeight: '700',
                color: color.secondary_1_0,
                fontSize: 16,
            }}
            style={props.style}
        >
            {props.title}
        </Typography>
    )
}


export const Home = () => {

    const dispatch = useAppDispatch()
    const config = useAppSelector(state => state.config)
    const [kioskInventories, setKioskInventories] = useState<GetKioskInventoryRes[]>([])
    const [qrModalProps, setQrModalProps] = useState({
        open: false,
        title: '',
        qrCode: '',
    })
    const [open, setOpen] = useState(false);

    useEffect(() => {
        (async () => {

        })()
    }, [])

    const area = getAreaList(kioskInventories)


    const getList = () => {
        return kioskInventories.filter(kioskInventory => {
            const selectAreaId = config.selectAreaId

            if (selectAreaId) {
                if (selectAreaId === 'ALL') {
                    return true
                }
                if (!kioskInventory.area) {
                    return false
                }
                if (kioskInventory.area.id === selectAreaId) {
                    return true
                }
            }
        })
            .sort((a, b) => a.kioskSaleState ? -1 : 1)
            .sort((a, b) => a.isStars ? -1 : 1)
    }


    return (
        <>

            <PageBox
                nav={(
                    <Nav
                        leftView={(
                            <LeftView
                                onAction={() => setOpen(!open)}
                            />
                        )}
                        title={I18n.t('appName')}
                        rightView={(
                            <RightView
                                onAction={async () => {
                                    const [kioskInventoryList] = await Promise.all([
                                        getKioskInventoryList(),
                                    ])
                                    setKioskInventories(kioskInventoryList.data)
                                }}
                            />
                        )}
                    />
                )}
                onLoadData={async () => {
                    const [kioskInventoryList] = await Promise.all([
                        getKioskInventoryList(),
                    ])
                    setKioskInventories(kioskInventoryList.data)

                }}
            >
                <>
                    <div
                        style={{
                            padding: 10,
                            flex: 1,
                            backgroundColor: '#F0F0EF',
                        }}
                    >
                        <HomeBodyTitle
                            title={I18n.t('Home__kioskInventoriesTitle')}
                            style={{
                                marginBottom: 10,
                                marginTop: 10,
                            }}
                        />
                        <HomeSelect
                            value={config.selectAreaId || 'ALL'}
                            data={area}
                            onValue={(id) => {
                                dispatch(setConfigData({
                                    ...config,
                                    selectAreaId: id
                                }))
                            }}
                            config={config}
                        />

                        <HomeKioskInventoryList
                            kioskInventories={getList()}
                            config={config}
                            onAddress={kioskInvntory => {
                                console.log('onAddress', kioskInvntory)

                                const ReactNativeWebView = window['ReactNativeWebView']
                                if (ReactNativeWebView) {
                                    ReactNativeWebView?.postMessage(JSON.stringify({ type: 'openUrl', url: `https://www.google.com/maps/search/?api=1&query=${kioskInvntory.geolocation}` }))
                                }

                            }}
                            onPressItem={kioskInvntory => {
                                console.log('onPressItem', kioskInvntory)
                            }}
                        />

                    </div>
                    <HomeQrModal
                        open={qrModalProps.open}
                        onClose={(event, reason) => {
                            console.log('onClose', event, reason)
                            setQrModalProps({
                                ...qrModalProps,
                                open: false,
                            })
                        }}
                        title={qrModalProps.title}
                        qrCode={qrModalProps.qrCode}
                    />


                </>
            </PageBox >

            <HomeDrawer
                open={open}
                onClose={() => setOpen(false)}
            />
        </>
    )
}